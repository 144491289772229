import { navigate } from "@reach/router";
import React from "react";

type Props = {
  navigate: typeof navigate;
};

class Account extends React.Component<Props> {
  public async componentDidMount() {
    await this.props.navigate("/account/orders");
  }
  public render() {
    return null;
  }
}

export default Account;
